<template>
  <section
    id="time"
  >
    <v-container class="fill-height">
      <v-row justify="center">
        <v-col>
          Time page
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  name: 'Time',
  components: {
  },
  data () {
    return {
      agreements: []
    }
  }
}
</script>

<style scoped>

</style>
